import React from 'react'
import './Team.css'
import Eddy from '../../assets/avatar/eddy.png'
import Alisha from '../../assets/avatar/alisha.png'
import Athene from '../../assets/avatar/athene.png'
import { motion } from 'framer-motion'

const Team = () => {
  return (
    <div className='team_container'>
      <section id='team'>
        <h5>The stars</h5>
        <h2>Meet the team</h2>
      </section>

      <div className='team_content'>

        <motion.div
        initial={{ opacity: 0, x: 50  }}
        whileInView={{ opacity: 1, x:0 }}
        transition={{ duration: 0.5,  ease: "easeIn" }}
        exit={{ opacity: 0, x: 50 }}
        className='team_card'>
          <div className='team_img'><img src={Eddy} alt='' /></div>
          <div className='team_info'>
            <h3>Eddy</h3>
            <small>Owner</small>
            <p>
            Eddy is a highly experienced hair stylist with an impressive career spanning almost 15 years. Having worked with reputable organizations like BBlunt, Snip, Papiillon, and Neomis, he has built a strong foundation of expertise in hairdressing. Eddy is renowned for his creativity and possesses a soft-spoken nature that allows him to connect effortlessly with his clients. He pays meticulous attention to detail and goes above and beyond to ensure that each customer receives the best possible service
            </p>
          </div>
        </motion.div>
        <motion.div
        initial={{ opacity: 0, x: -50  }}
        whileInView={{ opacity: 1, x:0 }}
        transition={{ duration: 0.5,  ease: "easeIn" }}
        exit={{ opacity: 0, x: -50 }}
        className='team_card'>
          <div className='team_img'><img src={Alisha} alt='' /></div>
          <div className='team_info'>
            <h3>Alisha</h3>
            <small>Nail Specialist</small>
            <p>With 13 years of experience in the beauty industry, Alisha is a highly skilled beautician who excels in all beauty services. Her extensive expertise and attention to detail make her a valuable asset to our salon. Alisha is known for her meticulous approach, ensuring that she delivers services that meet her own high standards of satisfaction. Her commitment to quality is evident in every beauty service she provides. Clients can trust Alisha to deliver exceptional results and enjoy a truly satisfying experience.
            </p>
          </div>
        </motion.div>
        <motion.div
        initial={{ opacity: 0, x: 50  }}
        whileInView={{ opacity: 1, x:0 }}
        transition={{ duration: 0.5,  ease: "easeIn" }}
        exit={{ opacity: 0, x: 50 }}
        className='team_card'>
          <div className='team_img'><img src={Athene} alt='' /></div>
          <div className='team_info'>
            <h3>Athene</h3>
            <small>Beauty Specialist</small>
            <p>Athene is a skilled beautician with a calming presence and a soft touch that brings comfort to every client. Her gentle approach and attention to detail ensure that each customer feels at ease throughout their beauty service. Athene's priority is to create a comfortable and relaxing environment where clients can truly unwind and enjoy their pampering experience. With her commitment to providing exceptional service, Athene ensures that every client leaves feeling rejuvenated and satisfied.
            </p>
          </div>
        </motion.div>

      </div>

    </div>
  )
}

export default Team