import React, { useRef } from 'react'
import './Contact.css'
import { MdEmail } from 'react-icons/md'
import { FaPhoneAlt } from 'react-icons/fa'
import emailjs from '@emailjs/browser';

const Contact = () => {
  
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_ep40sdp', 'template_s71ml6p', form.current, 'qsnkSq4OLeWrNtSzm')

    e.target.reset()
  };

  return (
    <section id='contact'>
      <h5>Get in touch</h5>
      <h2>Contact us</h2>

      <div className="container contact_container">

        <div className="contact_options">

          <article className='contact_option'>
            <MdEmail className='contact_option-icon' />
            <h4>Email</h4>
            <h5>elateunisexsalon@gmail.com</h5>
            <a href="mailto:elateunisexsalon@gmail.com" target="_blank" rel="noreferrer">Send a message</a>
          </article>

          <article className='contact_option'>
            <FaPhoneAlt className='contact_option-icon' />
            <h4>Phone</h4>
            <h5>+91-7084-040708</h5>
            <a href="tel:+917084040708" target="_blank" rel="noreferrer">Call</a>
          </article>

        </div>

        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder='Your Full Name' required />
          <input type="email" name='email' placeholder='Your Email' required />
          <textarea name="message" rows="7" placeholder='Your Message' required></textarea>
          <button type='submit' className='btn btn-primary'>Send Message</button>
        </form>

      </div>

    </section>
  )
}

export default Contact
