import React from 'react'
import './About.css'
import Abouthero from './Abouthero'
import Contact from '../contact us/Contact'
import Footer from '../footer/Footer'
import Team from '../team/Team'
import Gallery from '../gallery/Gallery'
import Review from '../reviews/Review'
import ScrollToTopButton from '../../ScrollToTopButton';
import { Helmet } from 'react-helmet-async';



function About() {

    return (
        <>
            <Helmet>
                <title>Home</title>
                <meta name="description" content="Welcome to our Elate! We offer a wide range of services to help you look and feel your best. Our team of highly trained and experienced stylists will work with you to create a look that is tailored to your individual needs and preferences. Whether you're looking for a new haircut, a fresh color, or a relaxing spa treatment, we have something for everyone. We offer a variety of hair services, including cuts, colors, highlights, and extensions. Our color specialists can help you achieve a natural-looking color or a bold and dramatic change. We also offer a range of spa services, including facials, massages, and waxing, to help you relax and rejuvenate. In addition to our traditional services, we also offer special treatments such as bridal and special occasion hairstyling, and makeup services. We use only the highest quality products in our salon to ensure that you receive the best possible service. We understand that your time is valuable, and that's why we offer online booking and flexible scheduling to make it easy for you to book an appointment that works for you. Give us a call or book your appointment online today! We're looking forward to helping you look and feel your best." />
                <link rel="canonical" href="/" />
            </Helmet>
            <div>
                <div className='home_container'>
                    <div className="bg-section" id='layer-1'></div>
                    <div className='about-section' id='layer-2'><Abouthero /></div>
                    <div className='gallery-section' id='layer-3'><Gallery /></div>
                    <div className='team-section' id='layer-4'><Team /></div>
                    <div className='review-section' id='layer-5'><Review /></div>
                    <div className='contact-section' id='layer-6'><Contact /></div>
                    <div className='footer-section' id='layer-7'><Footer /></div>
                </div>
                <ScrollToTopButton />
            </div>
        </>
    )
}

export default About