import React from 'react'
import './About_hero.css'
import Logo from '../../assets/logo/logo.png'
import Typo from '../../assets/video/typo.mp4'

import { motion } from 'framer-motion'

const About_hero = () => {
  return (
    <div>
      <motion.div 
            initial={{ opacity: 0, scale: 0 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.6,  ease: "easeInOut" }}
            viewport={{ once: true }}
            ><img src={Logo} alt="" id='logo_image_hero' />
      </motion.div>
      <video src={Typo} autoPlay loop muted defaultmuted="true" playsInline  preload="auto" id='video_hero'></video>
      {/* <div className='white-line'></div> */}
      <div className="textblock">          
          <div className="textblock-container">
          
          <motion.p id="textblock-content"
            initial={{ opacity: 0, y: -20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6,  ease: "easeInOut" }}
            exit={{ opacity: 0, y: -20 }}
            >
            Welcome to our Elate! We offer a wide range of services to help you look and feel your best. Our team of highly trained and experienced stylists will work with you to create a look that is tailored to your individual needs and preferences. Whether you're looking for a new haircut, a fresh color, or a relaxing spa treatment, we have something for everyone.

            We offer a variety of hair services, including cuts, colors, highlights, and extensions. Our color specialists can help you achieve a natural-looking color or a bold and dramatic change. We also offer a range of spa services, including facials, massages, and waxing, to help you relax and rejuvenate.

            In addition to our traditional services, we also offer special treatments such as bridal and special occasion hairstyling, and makeup services.

            We use only the highest quality products in our salon to ensure that you receive the best possible service.

            We understand that your time is valuable, and that's why we offer online booking and flexible scheduling to make it easy for you to book an appointment that works for you. Give us a call or book your appointment online today! We're looking forward to helping you look and feel your best.
          </motion.p>
        </div>
      </div>
      {/* <div className='white-line'></div> */}
    </div>
  )
}

export default About_hero