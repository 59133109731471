import React from 'react'
import './Review.css'
import Rev1 from '../../assets/reviews/rev1.jpg'
import Rev2 from '../../assets/reviews/rev2.jpg'
import Rev3 from '../../assets/reviews/rev3.jpg'
import Rev4 from '../../assets/reviews/rev4.jpg'
import Rev5 from '../../assets/reviews/rev5.jpg'
import Rev6 from '../../assets/reviews/rev6.jpg'
import Rev7 from '../../assets/reviews/rev7.jpg'
import Rev8 from '../../assets/reviews/rev8.jpg'
import Rev10 from '../../assets/reviews/rev10.jpg'
import Rev11 from '../../assets/reviews/rev11.jpg'

import {motion} from 'framer-motion'


const Review = () => {
  return (
    <div className='review_container'>
      <section id='review'>
        <h5>Our Gratitude</h5>
        <h2>The Reviews</h2>
      </section>
      <div className='review_wrapper'>
        <div className='line_1'>
          <motion.img 
          initial={{ x: -50  }}
          whileInView={{ x:0 }}
          transition={{ duration: 0.5,  ease: "easeIn" }}
          // exit={{ opacity: 0 }}
          src={Rev1} alt="" id='review_1'/>

          <motion.img
          initial={{ x: -50 }}
          whileInView={{ x:0 }}
          transition={{ duration: 0.5,  ease: "easeIn" }}
          // exit={{ opacity: 0 }}
          src={Rev2} alt="" id='review_2'/>

          <motion.img
          initial={{ x: -50  }}
          whileInView={{ x:0 }}
          transition={{ duration: 0.5,  ease: "easeIn" }}
          // exit={{ opacity: 0 }}
          src={Rev3} alt="" id='review_3'/>

          <motion.img 
          initial={{ y: 20 }}
          whileInView={{ y: 0 }}
          transition={{ duration: 0.5,  ease: "easeIn" }}
          // exit={{ opacity: 0 }}
          src={Rev4} alt="" id='review_4'/>

          <motion.img
          initial={{ y: 20  }}
          whileInView={{ y: 0 }}
          transition={{ duration: 0.5,  ease: "easeIn" }}
          // exit={{ opacity: 0 }}
          src={Rev5} alt="" id='review_5'/>

          <motion.img
          initial={{ y: 20  }}
          whileInView={{ y: 0}}
          transition={{ duration: 0.5,  ease: "easeIn" }}
          // exit={{ opacity: 0 }}
          src={Rev6} alt="" id='review_6'/>

          <motion.img
          initial={{ y: 20  }}
          whileInView={{ y: 0 }}
          transition={{ duration: 0.5,  ease: "easeIn" }}
          // exit={{ opacity: 0 }}
          src={Rev7} alt="" id='review_7'/>

          <motion.img
          initial={{ x:50 }}
          whileInView={{ x:0 }}
          transition={{ duration: 0.5,  ease: "easeIn" }}
          // exit={{ opacity: 0 }}
          src={Rev8} alt="" id='review_8'/>

          <motion.img
          initial={{ x:50 }}
          whileInView={{ x:0 }}
          transition={{ duration: 0.5,  ease: "easeIn" }}
          // exit={{ opacity: 0 }}
          src={Rev10} alt="" id='review_10'/>

          <motion.img
          initial={{ x:50 }}
          whileInView={{ x:0 }}
          transition={{ duration: 0.5,  ease: "easeIn" }}
          // exit={{ opacity: 0 }}
          src={Rev11} alt="" id='review_11'/>
        </div>
      </div>
    </div>
  )
}

export default Review