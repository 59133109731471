import React from 'react'
import './Gallery.css'
import Image1 from '../../assets/marquee/image1-min.JPG'
import Image2 from '../../assets/marquee/image2-min.JPG'
import Image3 from '../../assets/marquee/image3-min.JPG'
import Image4 from '../../assets/marquee/image4-min.JPG'
import Image5 from '../../assets/marquee/image5-min.JPG'
import Image6 from '../../assets/marquee/image6-min.JPG'
import Image7 from '../../assets/marquee/image7-min.JPG'
import Image8 from '../../assets/marquee/image8-min.JPG'
import Image9 from '../../assets/marquee/image9-min.JPG'
import Image10 from '../../assets/marquee/image10-min.JPG'

const Gallery = () => {
    return (
        <div>
        <section
        className='gallery_container'>
            <section className='gallery_title'>
                <h5>Memories</h5>
                <h2>The Gallery</h2>
            </section>

            <div id="marquee">
                <div class="marquee-container">
                    <div class="pic-container-rl">
                        <div class="pic">
                            <img src={Image1} alt="img" />
                        </div>
                        <div class="pic">
                            <img src={Image2} alt="img" />
                        </div>
                        <div class="pic">
                            <img src={Image3} alt="img" />
                        </div>
                        <div class="pic">
                            <img src={Image4} alt="img" />
                        </div>
                        <div class="pic">
                            <img src={Image5} alt="img" />
                        </div>
                        <div class="pic">
                            <img src={Image1} alt="img" />
                        </div>
                        <div class="pic">
                            <img src={Image2} alt="img" />
                        </div>
                        <div class="pic">
                            <img src={Image3} alt="img" />
                        </div>
                        <div class="pic">
                            <img src={Image4} alt="img" />
                        </div>
                        <div class="pic">
                            <img src={Image5} alt="img" />
                        </div>
                        <div class="pic">
                            <img src={Image1} alt="img" />
                        </div>
                        <div class="pic">
                            <img src={Image2} alt="img" />
                        </div>
                        <div class="pic">
                            <img src={Image3} alt="img" />
                        </div>
                        <div class="pic">
                            <img src={Image4} alt="img" />
                        </div>
                        <div class="pic">
                            <img src={Image5} alt="img" />
                        </div>
                    </div>
                </div>
            </div>
            <div id="marquee">
                <div class="marquee-container">
                    <div class="pic-container-lr">
                        <div class="pic">
                            <img src={Image6} alt="img" />
                        </div>
                        <div class="pic">
                            <img src={Image7} alt="img" />
                        </div>
                        <div class="pic">
                            <img src={Image8} alt="img" />
                        </div>
                        <div class="pic">
                            <img src={Image9} alt="img" />
                        </div>
                        <div class="pic">
                            <img src={Image10} alt="img" />
                        </div>
                        <div class="pic">
                            <img src={Image6} alt="img" />
                        </div>
                        <div class="pic">
                            <img src={Image7} alt="img" />
                        </div>
                        <div class="pic">
                            <img src={Image8} alt="img" />
                        </div>
                        <div class="pic">
                            <img src={Image9} alt="img" />
                        </div>
                        <div class="pic">
                            <img src={Image10} alt="img" />
                        </div>
                        <div class="pic">
                            <img src={Image6} alt="img" />
                        </div>
                        <div class="pic">
                            <img src={Image7} alt="img" />
                        </div>
                        <div class="pic">
                            <img src={Image8} alt="img" />
                        </div>
                        <div class="pic">
                            <img src={Image9} alt="img" />
                        </div>
                        <div class="pic">
                            <img src={Image10} alt="img" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </div>
    )
}

export default Gallery