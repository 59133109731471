import React from 'react'
import './Footer.css'
import { SiGooglemaps } from 'react-icons/si'
import { AiFillInstagram } from 'react-icons/ai'
import { AiFillFacebook } from 'react-icons/ai'

const Footer = () => {
  return (
    <footer>
      <a href="#top" className='footer_logo'>Elate Unisex Salon</a>

      <ul className='permalinks'>
        <li><a href="#top">Home</a></li>
        <li><a href="/Services">Services</a></li>
        <li><a href="/contact">Contact</a></li>
      </ul>

      <div className="footer_socials">
        <a href="https://maps.app.goo.gl/hDQTUL4pvkdcMV967" target="_blank" rel="noreferrer"><SiGooglemaps /></a>
        <a href="https://instagram.com/elate.salon?igshid=ZDdkNTZiNTM=" target="_blank" rel="noreferrer"><AiFillInstagram /></a>
        <a href="https://www.facebook.com/profile.php?id=100087910321337" target="_blank" rel="noreferrer"><AiFillFacebook /></a>
      </div>

      <div className="footer_copyright">
        <small>&copy; Elate Unisex Salon. All rights reserved</small>
      </div>
    </footer>
  )
}

export default Footer
