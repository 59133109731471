import React, { useState } from 'react'
import './Navbar.css'
import Logo from '../../assets/logo/logo.png'
import { GiHamburgerMenu } from 'react-icons/gi'
import { Link } from "react-router-dom";


const Navbar = () => {

  const [isNavExpanded, setIsNavExpanded] = useState(false);

  const handleLinkClick = () => {
    setIsNavExpanded(false);
  };

  return (
    <div>
      <nav className="navigation">
        <img src={Logo} alt="" className='logo-img' />
        <Link to="/" className="brand-name">
          Elate Salon
        </Link>
        <button className="hamburger" onClick={() => {
          setIsNavExpanded(!isNavExpanded);
        }}>
          <GiHamburgerMenu />
        </button>
        <div
          className={
            isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
          }>
          <ul>
            <li>
              <Link to="/" onClick={handleLinkClick}>Home</Link>
            </li>
            <li>
              <Link to="/Services" onClick={handleLinkClick}>Services</Link>
            </li>
            <li>
              <Link to="/Contact" onClick={handleLinkClick}>Contact Us</Link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  )
}

export default Navbar