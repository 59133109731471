import React from 'react';
import {BsFillArrowUpCircleFill} from 'react-icons/bs'

const ScrollToTopButton = () => {
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <button className="scroll-to-top-button" onClick={handleScrollToTop}>
      <div ><BsFillArrowUpCircleFill className='top-button'/></div>
    </button>
  );
};

export default ScrollToTopButton;
