import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';
import ScrollToTop from "./scrollToTop";
import { HelmetProvider } from 'react-helmet-async';


ReactDOM.render(
     <BrowserRouter>
          <HelmetProvider>
               <ScrollToTop />
               <App />
          </HelmetProvider>
     </BrowserRouter>
     , document.querySelector('#root')
);
