import React from 'react'
import {Route, Routes} from  'react-router-dom'
import Home from './components/about/Home'
import Navbar from './components/navbar/Navbar'
import Services from './components/services/Services'
import Contact from './components/contact us/Contact'

const App = () => {
  return (
    <>
      <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/Services' element={<Services />} />
          <Route path='/Contact' element={<Contact />} />
        </Routes>
    </>
  )
}

export default App