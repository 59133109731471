import React from 'react';
import './Services.css';
import Footer from '../footer/Footer';
import Haircut from '../../assets/services/haircut.webp';
import Color from '../../assets/services/color.webp';
import Facial from  '../../assets/services/facial.webp';
import Hairstyling from  '../../assets/services/hairstyling.webp';
import Nails from  '../../assets/services/nails.webp';
import Pedi from '../../assets/services/pedi.webp';
import Spa from '../../assets/services/spa.webp';
import Technical from '../../assets/services/technical.webp';
import Threads from '../../assets/services/threads.webp';
import Treatment from '../../assets/services/treatment.webp';
import Wax from '../../assets/services/wax.webp';
import ScrollToTopButton from '../../ScrollToTopButton';
import { Helmet } from 'react-helmet-async';


function Services() {

  return (
    <>
    <Helmet>
    <title>Services</title>
    <meta name="description" content="Experience the epitome of beauty and wellness at our exquisite beauty salon. Our dedicated team of skilled professionals offers a comprehensive range of services tailored to enhance your natural beauty. From trendy haircuts and vibrant hair color to rejuvenating facials, soothing massages, and impeccable nail care, we provide a sanctuary for relaxation and self-care. With a focus on quality, luxury, and personalized attention, we strive to create an unforgettable experience that leaves you feeling pampered, confident, and radiant. Discover the transformative power of our beauty services and embark on a journey of indulgence. Book your appointment today and let us unveil your true beauty."/>
    <link rel="canonical" href="/Services" />
    </Helmet>

    <div id='services'>
      <div className='services-title'><h1>Services</h1></div>

      <div className='services-head-text'>
        <span>Look and feel your best with our variety of salon services.
          From hair care to skin care, nail care, hair removal, and relaxation services, we have everything you need to achieve your desired look.
          <br /> Our experienced team of professionals uses high-quality products and techniques to provide customized solutions for each of our clients.
          <br /> Whether you're looking for a new haircut, a facial, a manicure, or a massage, our salon is here to help you look and feel your best.
          <br /> Contact us today to schedule your appointment and let us help you achieve your beauty goals
        </span>
      </div>
      {/* <div className='services-gallery'><ImageGallery items={images} 
      showFullscreenButton={false}
      showPlayButton={false}
      autoPlay={true}
      showThumbnails={false}
      lazyLoad={true}
      showNav={false}
      />
      </div> */}
      <div className='white-line'></div>
      <div className='service-catalog'>
      <div className='service-memo'>
        <div className='service-image'><img src={Haircut} alt='haircut'/></div>
        <div className='service-content'>
          <div className='service-title'><h1>Haircut</h1></div>
          <div className='white-line'></div>
          <div className='service-info'><p>Our hair cut service includes a consultation with one of our skilled stylists to determine the best haircut for your face shape, hair type, and personal style. We’ll also provide a relaxing shampoo and scalp massage before starting the cut, and finish with a style.</p></div>
          <div className='white-line'></div>
          <div className='service-list'>
            <ul>
              <li>Senior Stylist</li>
              <li>Salon Stylist</li>
              <li>Kids below 8 yrs</li>
              <li>Fringe Cut</li>
              <li>Beard Trim</li>
            </ul>
          </div>
        </div>
      </div>
      <div className='service-memo'>
      <div className='service-image'><img src={Color} alt='color'/></div>
      <div className='service-content'>
          <div className='service-title'><h1>Colour & Technical Works</h1></div>
          <div className='white-line'></div>
          <div className='service-info'><p>From subtle highlights to bold, dramatic changes, we can help you achieve the look you desire. We offer a variety of coloring techniques, including balayage, ombre, and traditional highlights, as well as root touch-ups and all-over color</p></div>
          <div className='white-line'></div>
          <div className='service-list'>
          <ul>
              <li>Root Touch Up</li>
              <li>Global</li>
              <li>Classic Highlights</li>
              <li>Balayage</li>
              <li>Ombre</li>
              <li>Panels</li>
            </ul>
          </div>
        </div>
                
      </div>
      <div className='service-memo'>
        <div className='service-image'><img src={Hairstyling} alt='style'/></div>
        <div className='service-content'>
          <div className='service-title'><h1>Hairstyle</h1></div>
          <div className='white-line'></div>
          <div className='service-info'><p>Our hair styling service offers a wide range of options to help you create the perfect look for any occasion. Whether you want a sleek and polished style or something more textured and messy, our stylists have the skills and expertise to make it happen.</p></div>
          <div className='white-line'></div>
          <div className='service-list'>
            <ul>
              <li>Shampoo & Blast Dry</li>
              <li>Shampoo & Blow Straight</li>
              <li>Blow In & Out</li>
              <li>Ironing & Tonging</li>
              <li>Braiding</li>
              <li>Up Do’s</li>
            </ul>
          </div>
        </div>
      </div>
      <div className='service-memo'>
      <div className='service-image'><img src={Technical} alt='technical'/></div>
        <div className='service-content'>
          <div className='service-title'><h1>Technical Service (Textures)</h1></div>
          <div className='white-line'></div>
          <div className='service-info'><p>Whether you want to add volume to fine hair, tame unruly curls, or create a sleek, straight style, we have the expertise and experience to help you achieve your goals. Our stylists are highly trained in the latest techniques for creating texture, including perms, relaxers, and keratin treatments.</p></div>
          <div className='white-line'></div>
          <div className='service-list'>
            <ul>
              <li>Perming</li>
              <li>Straightening/Smoothening</li>
              <li>Cysteine</li>
              <li>QOD</li>
              <li>Keratin</li>
            </ul>
          </div>
        </div>
        
      </div>
      <div className='service-memo'>
        <div className='service-image'><img src={Treatment} alt='treat'/></div>
        <div className='service-content'>
          <div className='service-title'><h1>Treatments</h1></div>
          <div className='white-line'></div>
          <div className='service-info'><p>Our hair treatments include deep conditioning, hair repair, and scalp treatments. Deep conditioning treatments can help restore moisture and nourishment to dry, damaged hair, leaving it soft, shiny, and more manageable. Hair repair treatments can help restore strength and health to hair that has been damaged by chemical treatments or heat styling.</p></div>
          <div className='white-line'></div>
          <div className='service-list'>
            <ul>
              <li>Instant Conditioning</li>
              <li>Loreal Hairspa</li>
              <li>Mythic Hairspa</li>
              <li>Express Ritual</li>
              <li>Experience Ritual</li>
            </ul>
          </div>
        </div>
      </div>
      <div className='service-memo'> 
        <div className='service-image'><img src={Spa} alt='spa'/></div>       
        <div className='service-content'>
          <div className='service-title'><h1>Relaxation</h1></div>
          <div className='white-line'></div>
          <div className='service-info'><p>we offer a range of services designed to help you de-stress and rejuvenate your mind and body. Our spa services include a variety of massages, facials, and body treatments. Our experienced and highly trained therapists will work with you to create a customized spa experience that meets your specific needs and preferences.</p></div>
          <div className='white-line'></div>
          <div className='service-list'>
            <ul>
            <li>Head Massage (30 Mins)</li>
            <li>Foot Reflexology (30 Mins)</li>
            <li>Back Massage (30 Mins)</li>
            </ul>
          </div>
        </div>
        
      </div>
      <div className='service-memo'>
        <div className='service-image'><img src={Threads} alt='thread'/></div>
        <div className='service-content'>
          <div className='service-title'><h1>Threading</h1></div>
          <div className='white-line'></div>
          <div className='service-info'><p>we offer threading services to help you achieve perfectly shaped eyebrows and a smooth, hair-free face. Threading is a precise and gentle hair removal technique that is perfect for removing unwanted facial hair.</p></div>
          <div className='white-line'></div>
          <div className='service-list'>
            <ul>
              <li>Eyebrows</li>
              <li>Forehead</li>
              <li>Upper Lip</li>
              <li>Chin</li>
              <li>Burns</li>
              <li>Full Face</li>
            </ul>
          </div>
        </div>
      </div>
      <div className='service-memo'>
      <div className='service-image'><img src={Pedi} alt='pedi'/></div>
        <div className='service-content'>
          <div className='service-title'><h1>Manicure, Pedicure</h1></div>
          <div className='white-line'></div>
          <div className='service-info'><p>we offer a wide range of manicure and pedicure services to help you achieve healthy, beautiful nails. Our experienced nail technicians use high-quality products and techniques to provide customized nail care solutions for all of our clients.</p></div>
          <div className='white-line'></div>
          <div className='service-list'>
            <ul>
              <li>Signature Pedicure</li>
              <li>Signature Manicure</li>
              <li>Regular Pedicure</li>
              <li>Regular Manicure</li>
              <li>Cut/File Hand & Feet</li>
              <li>Polish Change</li>
              <li>French Polish</li>
            </ul>
          </div>
        </div>
        
      </div>
      <div className='service-memo'>
        <div className='service-image'><img src={Wax} alt='wax'/></div>
        <div className='service-content'>
          <div className='service-title'><h1>Hair Removal</h1></div>
          <div className='white-line'></div>
          <div className='service-info'><p>we offer a variety of hair removal services to help you achieve smooth, hair-free skin. Our experienced and highly trained estheticians use the latest techniques and high-quality products to provide effective and long-lasting results.</p></div>
          <div className='white-line'></div>
          <div className='service-list'>
            <ul>
              <li>Under Arms</li>
              <li>Half Arms</li>
              <li>Full Arms</li>
              <li>Half Legs</li>
              <li>Full Legs</li>
              <li>Front</li>
              <li>Back</li>
              <li>Bikini Line</li>
              <li>Brazilian</li>
              <li>Full Face</li>
              
            </ul>
          </div>
        </div>
      </div>
      <div className='service-memo'>
      <div className='service-image'><img src={Facial} alt='facial'/></div>        
        <div className='service-content'>
          <div className='service-title'><h1>Skin Care</h1></div>
          <div className='white-line'></div>
          <div className='service-info'><p>We offer a variety of facials that are tailored to your individual skin type and concerns. Our facials are designed to cleanse, exfoliate, and nourish the skin, leaving it looking and feeling refreshed and rejuvenated. We also offer specialized facials, such as anti-aging facials and acne facials, that target specific skin concerns.</p></div>
          <div className='white-line'></div>
          <div className='service-list'>
            <ul>
              <li>Clean Up</li>
              <li>Jeannot Ceuticals Facial (Barcelona) 60 Mins</li>
              <li>-Hydra Boost (For Dry & Sensitive skin)</li>
              <li>-Instant Glow (For Tired & Dull skin)</li>
              <li>-Shine Control (For Oily and Acne prone skin)</li>
              <li>-Brilliance Whitening (For dark spots, pigmented & fine line)</li>
              <li>-Infinite Youth (For Anti-Aging)</li>
              <li>Jeannot Ceuticals Add-on Mask (Algae Mask)</li>
              <li>-Brightening Mask</li>
              <li>-Oil Control Mask</li>
              <li>-Anti-Ageing Mask</li>
              <li>Classic Fruity & Fun Facial</li>
              <li>-Orange (For all types of skin)</li>
              <li>-Strawberry (For Oily skin)</li>
              <li>-Watermelon (For Sensitive & Dry skin)</li>
            </ul>
          </div>
        </div>
        
      </div>
      <div className='service-memo'>
        <div className='service-image'><img src={Nails} alt='nail'/></div>
        <div className='service-content'>
          <div className='service-title'><h1>Nails</h1></div>
          <div className='white-line'></div>
          <div className='service-info'><p>At our salon, we offer a variety of nail care services to help you achieve healthy, beautiful nails. Our experienced nail technicians use high-quality products and techniques to provide customized nail care solutions for all of our clients.</p></div>
          <div className='white-line'></div>
          <div className='service-list'>
            <ul>
              <li>Manicure with Gel Polish</li>
              <li>Pedicure only toenails, Gel Polish</li>
              <li>Gel Polish Removal</li>
              <li>Extensions Removal</li>
              <li>Manicure, Extension, Gel Polish</li>
              <li>One Nail Extension</li>
              <li>Extensions Refilling</li>
              <li>Design/Art per Nail</li>
              <li>French</li>
              <li>Chrome Designs for all Nails</li>
              <li>Nail Strengthening</li>

            </ul>
          </div>
        </div>
      </div>
      </div>
      <ScrollToTopButton />
      <div className='footer-section' id='layer-7'><Footer /></div>
    </div>
    </>
  )

}

export default Services
